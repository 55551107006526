import React, { FC } from 'react'
import ExhibitorBar from '../ExhibitorBar'

const ProfileEvents: FC = () => {
  return (
    <>
      <ExhibitorBar exhibitor="xiaomi" />
      <ExhibitorBar exhibitor="intel" />
      <ExhibitorBar exhibitor="tesla" />
      <ExhibitorBar exhibitor="spotify" />
      <button className="profile__submit">Zapisz zmiany</button>
    </>
  )
}

export default ProfileEvents
