import React, { useEffect } from 'react'
import Dashboard from '../../views/Dashboard'
import { useParams } from 'react-router-dom'
import TabButton from '../../components/TabButton'
import NavigationHeader from '../../components/NavigationHeader'
import { IconsColor, Icons } from '../../utils/enums'
import defaultImage from '../../../images/news-item.png'
import { useSelector, useDispatch } from 'react-redux'
import { selectSlug, selectArticle } from '../../redux//dashboard/selectors'
import { asyncFetchArticle } from '../../redux//dashboard/async'
import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import translate from '../../translate'
import { selectUserToken } from '../../redux/user/selectors'

interface PageParams {
  id: string
}

const NewsItem: React.FC = () => {
  const { id } = useParams<PageParams>()

  const dispatch = useDispatch()

  const slug = useSelector(selectSlug)
  const article = useSelector(selectArticle(id))
  const token = useSelector(selectUserToken)

  useEffect(() => {
    if (slug !== undefined) {
      setTimeout(function() {
        dispatch(
          asyncFetchArticle({
            slug,
            id: id,
            additionalHeaders: { Authorization: `JWT ${token}` },
          }),
        )
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  // useEffect(() => {
  //   console.log(article)
  //   if (article?.poll_id) {
  //     getData(
  //       `${EVENT_URL}/galaxyunpackedcamp/${LANGUAGE}/api/polls/model/AgendaItem/${article?.poll_id}/`,
  //     ).then((data) => {
  //       console.log(data)
  //     })
  //   }
  // }, [article])

  // async function getData(url = '') {
  //   const response = await fetch(url, {
  //     method: 'GET',
  //     mode: 'cors',
  //     cache: 'no-cache',
  //     credentials: 'same-origin',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'App-Version': APP_VERSION || '',
  //       'Api-Key': API_KEY,
  //     },
  //   })
  //   return response.json()
  // }

  return (
    <Dashboard activePage={`article/${id}`}>
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader firstItem={translate('articles')} secondItem={article?.title} />
          <div className="news-item__wrapper">
            <div className="news-item__wrapper--left">
              <div className="news-item__date">
                <span>{article?.datetime_created.slice(0, 10)}</span>
                {/* <Person
              img={data[0].person.img}
              name={data[0].person.name}
              title={data[0].person.title}
            /> */}
                <div>
                  <TabButton
                    iconName={Icons.share}
                    iconColor={IconsColor.white}
                    iconColorHover={IconsColor.xiaomi}
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href)
                      dashboardMessage(DashboardMessageTypes.success, translate('copied_URL'))
                    }}
                  />
                </div>
              </div>
              <h1>{article?.title}</h1>
            </div>
            <div className="news-item__wrapper--right">
              {article?.photo_url ? (
                <img src={article?.photo_url || defaultImage} alt="news_image" />
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: article?.content || '' }}></div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default NewsItem
