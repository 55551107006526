export enum IconsColor {
  white = 'white',
  lightGrey = 'lightGrey',
  grey = 'grey',
  black = 'black',
  xiaomi = 'xiaomi',
  tesla = 'tesla',
  lightGreen = 'lightGreen',
}

export enum IconsFillType {
  fill = 'fill',
  stroke = 'stroke',
}

export enum Icons {
  bell = 'bell',
  calendar = 'calendar',
  heartFull = 'heartFull',
  heartOutlined = 'heartOutlined',
  folder = 'folder',
  scheduler = 'scheduler',
  mark = 'mark',
  arrowRight = 'arrowRight',
  star = 'star',
  share = 'share',
  edit = 'edit',
  download = 'download',
  heart = 'heart',
  people = 'people',
  eye = 'eye',
  search = 'search',
  settings = 'settings',
  settingsProfile = 'settingsProfile',
  back = 'back',
  smile = 'smile',
  attachment = 'attachment',
  phone = 'phone',
  cameraFull = 'cameraFull',
  message = 'message',
  speechBubble = 'speechBubble',
  remove = 'remove',
  pen = 'pen',
  exit = 'exit',
  rectangles = 'rectangles',
  user = 'user',
  bus = 'bus',
  tram = 'tram',
  subway = 'subway',
  phoneCall = 'phoneCall',
  mail = 'mail',
  plus = 'plus',
  rightUp = 'rightUp',
  next = 'next',
  prev = 'prev',
  tick = 'tick',
  cancel = 'cancel',
  email = 'email',
  building = 'building',
  menu = 'menu',
  upArrow = 'upArrow',
  downArrow = 'downArrow',
  meetingaIcon = 'meetingaIcon',
  minus = 'minus',
  board = 'board',
}

export enum Color {
  orange = 'orange',
  blue = 'blue',
  yellow = 'yellow',
  green = 'green',
  purple = 'purple',
  red = 'red',
  lightGreen = 'lightGreen',
}

export enum MethodProps {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete',
}

export enum DashboardMessageTypes {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export enum Languages {
  pl = 'pl',
  en = 'en',
}
