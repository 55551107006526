import React, { FC } from 'react'

import Logo from '../../../images/building.svg'

interface Props {
  id?: number
  logo?: string
  exhibitor?: string
  exhibitorStand?: string
  description?: string
  onClick?: (value: number | undefined) => void
}

const ExhibitorBarDetailed: FC<Props> = ({ id, onClick, logo, exhibitor }) => {
  return (
    <button className="exhibitor-bar-detailed" onClick={() => id && onClick?.(id)}>
      <div className="exhibitor-bar-detailed__logo">
        <img src={logo || Logo} alt="logo" />
      </div>

      <div className="exhibitor-bar-detailed__wrapper">
        <div className="exhibitor-bar-detailed__upper-part">
          <div className="exhibitor-bar-detailed__text">
            <span>{exhibitor}</span>
          </div>
          {/* <button className="exhibitor-bar-detailed__heart">
            <Icon name={Icons.heartOutlined} color={IconsColor.grey} type={IconsFillType.stroke} />
          </button> */}
        </div>
      </div>
    </button>
  )
}

export default ExhibitorBarDetailed
