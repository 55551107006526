import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../../views/Dashboard'
import MainTitle from '../../components/MainTitle'
import NavigationHeader from '../../components/NavigationHeader'

import { useSelector, useDispatch } from 'react-redux'
import { selectSlug, selectPeopleItem, selectMenuItems } from '../../redux/dashboard/selectors'
import { asyncFetchPeopleItem } from '../../redux/dashboard/async'
import Person from '../../components/Person'
import translate from '../../translate'
import { selectUserToken } from '../../redux/user/selectors'

interface PageParams {
  id: string
}

const Lecturer: FC = () => {
  const { id } = useParams<PageParams>()
  const title = useSelector(selectMenuItems)

  const peopleItem = useSelector(selectPeopleItem(id))
  const slug = useSelector(selectSlug)
  const token = useSelector(selectUserToken)

  const dispatch = useDispatch()

  useEffect(() => {
    if (slug !== undefined) {
      dispatch(
        asyncFetchPeopleItem({
          slug,
          id: id,
          additionalHeaders: { Authorization: `JWT ${token}` },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const getTitleName = () => {
    return title?.find((element) => element.type === 'People')?.title
  }

  return (
    <Dashboard activePage="people">
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader
            firstItem={getTitleName() || translate('people')}
            secondItem={`${peopleItem?.firstname} ${peopleItem?.lastname}`}
          />
          <div className="main__top">
            <MainTitle title={getTitleName() || translate('people')} />
          </div>

          <Person
            img={peopleItem?.photo_url}
            name={`${peopleItem?.firstname} ${peopleItem?.lastname}`}
            title={peopleItem?.post}
            status={peopleItem?.short_content}
            phone={peopleItem?.phone}
            email={peopleItem?.email}
            className="person-big"
          />

          {peopleItem?.content && (
            <div
              className="person-content"
              dangerouslySetInnerHTML={{ __html: peopleItem?.content }}
            ></div>
          )}
        </div>
      </div>
    </Dashboard>
  )
}

export default Lecturer
