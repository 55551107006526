export const cutString = (str: string, num: number) => {
  return str.length > num ? `${str.slice(0, num)}...` : str
}

export const getURLName = (url: string) => {
  const splittedURL = url.split('/')
  return splittedURL[splittedURL.length - 1]
}

export const getDayName = (day: number, lang: string) => {
  switch (day) {
    case 0:
      return lang === 'en' ? 'Sunday' : 'Niedziela'
    case 1:
      return lang === 'en' ? 'Monday' : 'Poniedziałek'
    case 2:
      return lang === 'en' ? 'Tuesday' : 'Wtorek'
    case 3:
      return lang === 'en' ? 'Wednesday' : 'Środa'
    case 4:
      return lang === 'en' ? 'Thursday' : 'Czwartek'
    case 5:
      return lang === 'en' ? 'Friday' : 'Piątek'
    case 6:
      return lang === 'en' ? 'Saturday' : 'Sobota'
    default:
      return lang === 'en' ? 'Wrong day' : 'Nieprawidłowy dzień'
  }
}

/**
 * @param {string} date new Date().toISOString()
 */
export const getTimeFromDate = (date: string) => {
  return date.slice(11, 16)
}

/**
 * @param {string} date new Date().toISOString()
 */
export const formatDate = (date: string) => {
  return date.slice(0, 10)
}

interface FindIndexProps {
  id: number
  [key: string]: any
}

export const findIndex = (arr: FindIndexProps[], id: number) => {
  return arr.findIndex((element: FindIndexProps) => element.id === id)
}

export const errorsToArray = (errors: any[]) => {
  console.log(errors, 'errors')
  const arr = []

  for (const key in errors) {
    arr.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${errors[key]}`)
  }

  return arr
}
