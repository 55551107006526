import React, { FC, useState } from 'react'
import Dashboard from '../../views/Dashboard'
import { useParams } from 'react-router-dom'
import NavigationHeader from '../../components/NavigationHeader'

import MainTitle from '../../components/MainTitle'
import MainSearch from '../../components/MainSearch'
import TileItem from '../../components/TileItem'
import defaultImage from '../../../images/news-default.png'

import { useSelector } from 'react-redux'
import { selectArticles, selectMenuItems } from '../../redux/dashboard/selectors'
import { ArticlesProps } from '../../redux/dashboard/interfaces'
import { cutString } from '../../utils/functions'
import searchSubstring from '../../helpers/searchSubstring'
import translate from '../../translate'

interface PageParams {
  id: string
}

const News: FC = () => {
  const { id } = useParams<PageParams>()

  const articles = useSelector(selectArticles)
  const menuItems = useSelector(selectMenuItems)
  const [search, setSearch] = useState('')

  return (
    <Dashboard activePage={`articles/${id}`}>
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader
            firstItem={
              menuItems?.find((element) => element.articles_category_id === Number(id))?.title ||
              translate('articles')
            }
          />
          <div className="main__top">
            <MainTitle
              title={
                menuItems?.find((element) => element.articles_category_id === Number(id))?.title ||
                translate('articles')
              }
            />
            <MainSearch value={search} onChange={setSearch} />
          </div>
          <ul className="tiles__wrapper">
            {articles
              ?.filter((element: ArticlesProps) => element.articles_category_id === Number(id))
              .map((element: ArticlesProps) => {
                if (searchSubstring(element.title, search)) {
                  return (
                    <TileItem
                      key={element.id}
                      id={element.id}
                      link="article"
                      image={element.photo_url || defaultImage}
                      date={element.datetime_created.slice(0, 10)}
                      text={cutString(element.title || '', 64)}
                    />
                  )
                }
                return null
              })}
          </ul>
        </div>
      </div>
    </Dashboard>
  )
}

export default News
