import React, { FC } from 'react'
import Icon from '../Icon'
import { IconsColor, Icons } from '../../utils/enums'

interface Props {
  name: string
  address?: string
}

const Localization: FC<Props> = ({ name, address }) => {
  return (
    <>
      <div className="localization">
        <div className="localization__mark">
          <Icon name={Icons.mark} color={IconsColor.black} hoverColor={IconsColor.xiaomi} />
        </div>
        <div className="localization__content">
          <span>{name}</span>
          {address && <span>{address}</span>}
        </div>
        <Icon
          className="localization__arrow"
          name={Icons.arrowRight}
          color={IconsColor.black}
          hoverColor={IconsColor.xiaomi}
        />
      </div>
    </>
  )
}

export default Localization
