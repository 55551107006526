import React, { FC } from 'react'
import Dashboard from '../../views/Dashboard'
import MainTitle from '../../components/MainTitle'

import { useSelector } from 'react-redux'
import { selectAddresses } from '../../redux/dashboard/selectors'
import Contact from '../../components/Contact'
import translate from '../../translate'

const Access: FC = () => {
  const addresses = useSelector(selectAddresses)

  return (
    <Dashboard activePage="access">
      <div className="main__container">
        <div className="main__wrapper">
          <div className="main__top">
            <MainTitle title={translate('access')} />
          </div>
          {addresses?.map((element) => {
            return element.show_in_access ? <Contact key={element.id} {...element} /> : null
          })}
        </div>
      </div>
    </Dashboard>
  )
}

export default Access
