import L from 'leaflet'
import greenMark from '../../images/greenMark.png'
import blueMark from '../../images/blueMark.png'
import greyMark from '../../images/greyMark.png'
import shadowMark from '../../images/marker-shadow.png'

export const greenIcon = new L.Icon({
  iconUrl: greenMark,
  shadowUrl: shadowMark,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [1, -34],
  shadowSize: [41, 41],
})

export const blueIcon = new L.Icon({
  iconUrl: blueMark,
  shadowUrl: shadowMark,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  tooltipAnchor: [1, -34],
  shadowSize: [41, 41],
})

export const greyIcon = new L.Icon({
  iconUrl: greyMark,
  shadowUrl: shadowMark,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  tooltipAnchor: [1, -34],
  shadowSize: [41, 41],
})
