import React, { FC, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Login from '../../views/Login'
import LoginInput from '../../components/LoginInput'
import { selectUserLoading } from '../../redux/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import translate from '../../translate'

import { Button } from 'antd'
import { asyncFetchPasswordResetConfirm } from '../../redux/user/async'

const NewPasswordPage: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const loading = useSelector(selectUserLoading)
  const userToken = location.search.split('?token=')[1]

  const [userPassword, setUserPassword] = useState({
    new_password: '',
    password_confirm: '',
  })

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const body = { password: userPassword.new_password, token: userToken }
    dispatch(
      asyncFetchPasswordResetConfirm({
        additionalHeaders: {},
        body,
        isSuccess: () => history.push('/logowanie'),
      }),
    )
  }

  return (
    <Login>
      <div className="login-page">
        <h1>{translate('change_password')}</h1>
        <p>{translate('change_password_text')}</p>

        <form onSubmit={handleSubmit}>
          <LoginInput
            label={translate('new_password')}
            name="new_password"
            required
            minLength={2}
            placeholder={translate('new_password')}
            type="password"
            onChange={setUserPassword}
          />
          <LoginInput
            label={translate('repeat_new_password')}
            name="password_confirm"
            type="password"
            minLength={2}
            placeholder={translate('repeat_new_password')}
            required
            onChange={setUserPassword}
          />

          <Button type="primary" loading={loading} htmlType="submit">
            {translate('change_password')}
          </Button>
        </form>

        <div className="login-page__links">
          <span>
            {translate('no_account')}{' '}
            <Link className="login-page__link" to="/rejestracja">
              {translate('register')}
            </Link>
          </span>
          <Link className="login-page__link" to="/logowanie">
            {translate('sign_in')}
          </Link>
        </div>
      </div>
    </Login>
  )
}

export default NewPasswordPage
