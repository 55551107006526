import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import ArrowRight from '../../../images/arrow-right-grey.svg'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../Icon'
import translate from '../../translate'

interface Props {
  firstItem?: string
  secondItem?: string
}

const NavigationHeader: FC<Props> = ({ firstItem, secondItem }) => {
  const history = useHistory()

  return (
    <div className="navigation-header">
      <button onClick={() => history.goBack()} className="navigation-header-button">
        <Icon name={Icons.back} color={IconsColor.lightGrey} type={IconsFillType.stroke} />
        <span>{translate('back')}</span>
      </button>
      {firstItem && (
        <span className={secondItem ? 'navigation-header--grey' : ''}>{firstItem}</span>
      )}
      {secondItem && (
        <>
          <img src={ArrowRight} alt="next" />
          <span>{secondItem}</span>
        </>
      )}
    </div>
  )
}

export default NavigationHeader
