import React, { FC } from 'react'
interface Props {
  title: string
  className?: string
}

const MainTitle: FC<Props> = ({ title, className }) => {
  return (
    <div className={`main__title ${className || ''}`}>
      <h1>{title}</h1>
    </div>
  )
}

export default MainTitle
