import { ActionReducerMapBuilder, createSlice, AsyncThunk } from '@reduxjs/toolkit'
import {
  asyncFetchLogin,
  asyncFetchAccountUpdate,
  asyncFetchAccountDelete,
  asyncFetchTokenRefresh,
  asyncFetchRegister,
  asyncRegisterEvent,
  asyncFetchUserDetails,
} from './async'
import { User } from './interfaces'

import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'

interface UserState {
  loading: boolean
  error: boolean
  data: User | null
  eventsFavorites: string[]
  notes: {
    id: number
    text: string
    title: string
  }[]
  token: string | null
}

const initialState: UserState = {
  error: false,
  loading: false,
  data: null,
  eventsFavorites: [],
  notes: [
    {
      id: 1,
      title: 'Testowa notatka',
      text: 'Tutaj możesz sporządzić swoje notatki. Wypróbuj mnie!',
    },
  ],
  token: null,
}

//TODO: https://github.com/Microsoft/TypeScript/issues/1213 replace any

const asyncActions = <T extends AsyncThunk<any, any, any>>(
  asyncFetch: T,
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(asyncFetch.pending, (state) => {
    state.loading = true
    state.error = false
  })
  builder.addCase(asyncFetch.rejected, (state, action) => {
    state.loading = false
    state.error = true
  })
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      state.loading = false
      state.error = false
      state.data = null
      state.token = null
      dashboardMessage(DashboardMessageTypes.success, 'Wylogowano.')
    },
    addEventToFavorites(state, action) {
      if (!state.eventsFavorites?.includes(action.payload)) {
        state.eventsFavorites?.push(action.payload)
      }
    },
    deleteEventFromFavorites(state, action) {
      const copyFavoriteEvents = state.eventsFavorites.filter(
        (element) => element !== action.payload,
      )
      state.eventsFavorites = copyFavoriteEvents
    },
    addNote(state, action) {
      state.notes.push(action.payload)
    },
    deleteNote(state, action) {
      state.notes = state.notes.filter((element) => element.id !== action.payload)
    },
    editNote(state, action) {
      state.notes[action.payload.id].title = action.payload.title
      state.notes[action.payload.id].text = action.payload.text
    },
    resetSlice(state) {
      return initialState
    },
  },
  extraReducers: (builder) => {
    asyncActions(asyncFetchAccountDelete, builder)
    builder.addCase(asyncFetchAccountDelete.fulfilled, (state, action) => {
      state.loading = false
      state.data = null
      state.token = null
      dashboardMessage(DashboardMessageTypes.success, 'Konto zostało usunięte.')
    })

    asyncActions(asyncFetchAccountUpdate, builder)
    builder.addCase(asyncFetchAccountUpdate.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload && typeof action.payload !== 'string') {
        state.data = action.payload
        dashboardMessage(DashboardMessageTypes.success, 'Zaktualizowano dane.')
      }
    })

    asyncActions(asyncFetchRegister, builder)
    builder.addCase(asyncFetchRegister.fulfilled, (state, action) => {
      state.data = action.payload || null
      state.token = action.payload?.token || null
      if (action.payload) {
        dashboardMessage(DashboardMessageTypes.success, 'Zarejestrowano.')
      }
      state.loading = false
    })

    asyncActions(asyncFetchLogin, builder)
    builder.addCase(asyncFetchLogin.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload || null
      state.token = action.payload?.token || null
      if (action.payload) {
        dashboardMessage(DashboardMessageTypes.success, 'Zalogowano.')
      }
    })

    asyncActions(asyncFetchTokenRefresh, builder)
    builder.addCase(asyncFetchTokenRefresh.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload || null
      state.token = action.payload?.token || null
    })

    asyncActions(asyncRegisterEvent, builder)
    builder.addCase(asyncRegisterEvent.fulfilled, (state, action: any) => {
      if (state.data && action && action.payload) {
        state.data.registered_events = action.payload.registered_events
      }
      state.loading = false
    })

    asyncActions(asyncFetchUserDetails, builder)
    builder.addCase(asyncFetchUserDetails.fulfilled, (state, action) => {
      state.data = action.payload || null
      state.loading = false
    })
  },
})

export const {
  logout,
  addEventToFavorites,
  deleteEventFromFavorites,
  addNote,
  editNote,
  deleteNote,
  resetSlice,
} = userSlice.actions
export default userSlice.reducer
