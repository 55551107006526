interface Inputs {
  login?: string
  password?: string
  repeatPassword?: string
  email?: string
  name?: string
  surname?: string
  position?: string
  company?: string
  phoneNumber?: string
  networking?: boolean
  privacyPolicy?: boolean
}

type UseStateFunction = (a: any) => void

const handleInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  fn: UseStateFunction,
  type = 'text',
  isUserAgreement = false,
): void => {
  const { name, value } = event.target

  return fn((state: Inputs) => {
    const inputsState: any = { ...state }

    if (type === 'checkbox' && isUserAgreement) {
      if (inputsState.user_agreements.find((element: string) => element === name)) {
        const newAgreements = inputsState.user_agreements.filter(
          (element: string) => element !== name,
        )
        inputsState.user_agreements = newAgreements
        return inputsState
      } else {
        inputsState.user_agreements.push(name)
        return inputsState
      }
    }

    if (type === 'checkbox') {
      inputsState[name] = !inputsState[name]
    } else {
      inputsState[name] = value
    }
    return inputsState
  })
}

export default handleInput
