import React, { FC, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import { useSelector } from 'react-redux'
import { selectEventConfiguration, selectWidth } from '../../redux/dashboard/selectors'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../../components/Icon'

export interface Props {
  children: React.ReactNode
  activePage?: string
  width?: number
}

const Dashboard: FC<Props> = ({ children, activePage }) => {
  const event = useSelector(selectEventConfiguration)
  const width = useSelector(selectWidth)
  const [mobileView, setMobileView] = useState(false)

  return (
    <div className="App">
      {mobileView && <Header className="header--mobile" />}
      <Sidebar activePage={activePage} className={mobileView ? 'mobile-bar' : undefined} />

      {!mobileView && (
        <main>
          {width >= 1280 && <Header />}
          {children}
        </main>
      )}

      <div className="sidebar__mobile-bar-wrapper">
        <span>{event?.name}</span>
        <button className="sidebar__mobile-bar-btn" onClick={() => setMobileView((prev) => !prev)}>
          {mobileView ? (
            <Icon
              name={Icons.cancel}
              color={IconsColor.white}
              hoverColor={IconsColor.white}
              type={IconsFillType.fill}
            />
          ) : (
            <Icon
              name={Icons.menu}
              color={IconsColor.white}
              hoverColor={IconsColor.white}
              type={IconsFillType.fill}
            />
          )}
        </button>
      </div>
    </div>
  )
}

export default Dashboard
