import React, { FC } from 'react'
import { IconsColor, Icons } from '../../utils/enums'
import Icon from '../Icon'

interface Props {
  id: number
  title: string
  text: string
  edit: (a: number) => void
  remove: (a: number) => void
  isActive: number | null
  setIsActive: (a: number | null) => void
}

const Note: FC<Props> = ({ id, title, text, edit, remove, isActive, setIsActive }) => {
  return (
    <div className={`note ${isActive === id && 'note--active'}`}>
      <div className="note__items">
        <h1 className="note__title">{title}</h1>
        <hr />
        <p className="note__text">{text}</p>
      </div>
      <div className="note__icons">
        <button
          className="note__button"
          onClick={() => {
            edit(id)
            setIsActive(id)
          }}
        >
          <Icon pointer={true} name={Icons.edit} color={IconsColor.xiaomi} />
        </button>
        <button className="note__button" onClick={() => remove(id)}>
          <Icon pointer={true} name={Icons.remove} color={IconsColor.xiaomi} />
        </button>
      </div>
    </div>
  )
}

export default Note
