import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MapContainer } from 'react-leaflet'
import { MapMark, MapView } from '../../components/ExhibitorMap'

import Dashboard from '../../views/Dashboard'
import NavigationHeader from '../../components/NavigationHeader'

import MainTitle from '../../components/MainTitle'
import MainSearch from '../../components/MainSearch'
import MainAside from '../../components/MainAside'
import MainAsideItem from '../../components/MainAsideItem'

import TabTitle from '../../components/TabTitle'

import searchSubstring from '../../helpers/searchSubstring'
import getIcon from '../../helpers/getIcon'

import { useSelector } from 'react-redux'
import { selectMapAreas, selectMapPoints } from '../../redux/dashboard/selectors'
import { MapAreasProps, MapPointsProps } from '../../redux/dashboard/interfaces'
import translate from '../../translate'
interface PageParams {
  exhibitorName: string
}

const Map: FC = () => {
  const { exhibitorName } = useParams<PageParams>()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<null | number>(null)

  const mapAreas = useSelector(selectMapAreas)
  const mapPoints = useSelector(selectMapPoints)

  return (
    <Dashboard activePage="map">
      <div className="main__container">
        <MainAside>
          <div className="main-aside-search-wrapper">
            <button
              className="exhibitor-map__show-all"
              onClick={() => {
                setSelected(null)
                setSearch('')
              }}
            >
              {translate('show_all')}
            </button>
            <MainSearch value={search} onChange={setSearch} />
          </div>
          <TabTitle title={translate('exhibitors')} />
          {mapPoints?.map((standElement: MapPointsProps) => {
            const { id, name } = standElement
            return (
              searchSubstring(name, search) && (
                <MainAsideItem
                  key={id}
                  img={getIcon('stand')}
                  title={name}
                  isSelected={id === selected}
                  exhibitorName={exhibitorName}
                  mapIcon={true}
                  id={standElement.id}
                  onClick={setSelected}
                />
              )
            )
          })}
        </MainAside>
        <div className="main__wrapper">
          <NavigationHeader firstItem={translate('map')} />
          <div className="main__top">
            <MainTitle title={translate('map')} />
          </div>
          {mapAreas?.map((mapElement: MapAreasProps) => {
            const { map_width, map_height, map_url } = mapElement

            return (
              <MapContainer
                key={mapElement.id}
                className="exhibitor-map"
                center={[0, 0]}
                zoom={14}
                scrollWheelZoom={true}
              >
                <MapView x={map_width} y={map_height} mapUrl={map_url} />

                {mapPoints?.map((mapMarkElement: MapPointsProps) => {
                  const { map_x, map_y, map_area_id, name } = mapMarkElement
                  return (
                    <MapMark
                      key={mapMarkElement.id}
                      x={map_x}
                      y={map_y}
                      name={name}
                      isDisplayed={map_area_id === mapElement.id && searchSubstring(name, search)}
                      selectedId={selected}
                      isSelected={mapMarkElement.id === selected}
                      id={mapMarkElement.id}
                      onClick={setSelected}
                    />
                  )
                })}
              </MapContainer>
            )
          })}
        </div>
      </div>
    </Dashboard>
  )
}

export default Map
