import Star from '../../images/star.svg'
import Edit from '../../images/edit.svg'
import Send from '../../images/send.svg'
import People from '../../images/people.svg'
import Eye from '../../images/eye.svg'
import Display from '../../images/display.svg'
import Micro from '../../images/micro.svg'
import Error from '../../images/error.svg'
import Camera from '../../images/camera.svg'
import Stand from '../../images/stand.svg'
import XiaomiLogo from '../../images/xiaomi-logo.png'
import Phone from '../../images/phone.svg'
import Message from '../../images/message.svg'
import CameraFilled from '../../images/camera-filled.svg'
import SpeechBubble from '../../images/speech-bubble.svg'

const getIcon = (icon: string): string => {
  switch (icon) {
    case 'star':
      return Star
    case 'edit':
      return Edit
    case 'send':
      return Send
    case 'people':
      return People
    case 'eye':
      return Eye
    case 'display':
      return Display
    case 'micro':
      return Micro
    case 'error':
      return Error
    case 'camera':
      return Camera
    case 'stand':
      return Stand
    case 'xiaomi-logo':
      return XiaomiLogo
    case 'phone':
      return Phone
    case 'speech-bubble':
      return SpeechBubble
    case 'message':
      return Message
    case 'camera-filled':
      return CameraFilled
    default:
      return Star
  }
}

export default getIcon
