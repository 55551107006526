import React from 'react'
import { styled, Theme } from '@material-ui/core/styles'
import Video from 'twilio-video'
import { Link, Typography, Paper, Grid } from '@material-ui/core'
import MenuBar from '../../../twilio/components/MenuBar/MenuBar'
import MobileTopMenuBar from '../../../twilio/components/MobileTopMenuBar/MobileTopMenuBar'
import PreJoinScreens from '../../../twilio/components/PreJoinScreens/PreJoinScreens'
import ReconnectingNotification from '../../../twilio/components/ReconnectingNotification/ReconnectingNotification'
import RecordingNotifications from '../../../twilio/components/RecordingNotifications/RecordingNotifications'
import Room from '../../../twilio/components/Room/Room'

import useHeight from '../../../twilio/hooks/useHeight/useHeight'
import useRoomState from '../../../twilio/hooks/useRoomState/useRoomState'

// const data = [
//   {
//     id: 1,
//     name: 'Poznaj nas bliżej | ŁUKASZ KOŚNIK',
//     start: '13:00',
//     end: '14:00',
//     showTime: true,
//     desc: 'RITS, czyli skrót od Relyon IT Services...',
//     img: 'images/people/1.jpg',
//     person: 'Łukasz Kośnik',
//     link: 'https://mail.trioconferences.pl/webinar/0/spotkanie-integracyjne-rits',
//     transmission: {
//       img: 'images/people/transmission_person.png',
//       person: 'Anna',
//     },
//   },
// ]

// const Transmission: React.FC = () => {
//   return (
//     <Dashboard>
//       <div className="main__container">
//         <div className="main__wrapper">
//           <NavigationHeader firstItem="Transmisja" />
//           <div className="transmission__buttons">
//             <TabButton
//               className="--grey"
//               text="9/10"
//               iconName={Icons.people}
//               iconColorHover={IconsColor.xiaomi}
//             />
//             <TabButton
//               className="--grey"
//               text="320"
//               iconName={Icons.eye}
//               iconColorHover={IconsColor.xiaomi}
//             />
//           </div>
//           <div className="transmission__container">
//             <ul>
//               <TransmissionPerson
//                 image={data[0].transmission.img}
//                 name={data[0].transmission.person}
//               />
//               <TransmissionPerson
//                 image={data[0].transmission.img}
//                 name={data[0].transmission.person}
//               />
//               <TransmissionPerson
//                 image={data[0].transmission.img}
//                 name={data[0].transmission.person}
//               />
//               <TransmissionPerson
//                 image={data[0].transmission.img}
//                 name={data[0].transmission.person}
//               />
//               <TransmissionPerson
//                 image={data[0].transmission.img}
//                 name={data[0].transmission.person}
//               />
//               <TransmissionPerson
//                 image={data[0].transmission.img}
//                 name={data[0].transmission.person}
//               />
//               <TransmissionPerson
//                 image={data[0].transmission.img}
//                 name={data[0].transmission.person}
//               />
//             </ul>
//           </div>
//           <div className="transmission__bar">
//             <TransmissionBarButton icon="camera" />
//             <TransmissionBarButton icon="micro" />
//             <TransmissionBarButton icon="display" />
//             <TransmissionBarButton icon="error" />
//           </div>
//         </div>
//         {/* <InfoBar data={data[0]} /> */}
//       </div>
//     </Dashboard>
//   )
// }

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
})

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}))

function Transmission() {
  const roomState = useRoomState()

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight()

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={6}>
            <Paper>
              <Typography variant="h4">Browser or context not supported</Typography>
              <Typography>
                Please open this application in one of the{' '}
                <Link
                  href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                  target="_blank"
                  rel="noopener"
                >
                  supported browsers
                </Link>
                .
                <br />
                If you are using a supported browser, please ensure that this app is served over a{' '}
                <Link
                  href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
                  target="_blank"
                  rel="noopener"
                >
                  secure context
                </Link>{' '}
                (e.g. https or localhost).
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  )
}

export default Transmission
