import React, { FC, useState } from 'react'
import Dashboard from '../../views/Dashboard'
import NavigationHeader from '../../components/NavigationHeader'

import MainTitle from '../../components/MainTitle'
import MainAside from '../../components/MainAside'

import TabTitle from '../../components/TabTitle'

import Note from '../../components/Note'
import setMaxId from '../../utils/setMaxId'

import { addNote, editNote, deleteNote } from '../../redux/user/slice'
import { selectNotes } from '../../redux/user/selectors'
import { useSelector, useDispatch } from 'react-redux'
import translate from '../../translate'

enum NoteNames {
  title = 'title',
  text = 'text',
}

const Notes: FC = () => {
  const notes = useSelector(selectNotes)
  const dispatch = useDispatch()

  const [notesForm, setNotesForm] = useState({
    id: setMaxId(notes),
    title: '',
    text: '',
  })
  const [isActive, setIsActive] = useState<null | number>(null)

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const noteIndex = notes.findIndex((element) => element.id === notesForm.id)

    if (noteIndex >= 0) {
      dispatch(editNote({ id: noteIndex, title: notesForm.title, text: notesForm.text }))
    } else {
      dispatch(addNote(notesForm))
    }

    setNotesForm({
      id: setMaxId(notes) + 1,
      title: '',
      text: '',
    })

    setIsActive(null)
  }

  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const { name, value } = event.target

    setNotesForm((prev) => {
      const state = { ...prev }
      if (name === NoteNames.title || name === NoteNames.text) {
        state[name] = value
      }
      return state
    })
  }

  const edit = (id: number) => {
    const note = notes.find((element) => element.id === id)
    if (note) {
      setNotesForm({ id: note.id, title: note.title, text: note.text })
    }
  }

  const remove = (id: number) => {
    const note = notes.find((element) => element.id === id)
    if (note) {
      const filteredState = notes.filter((element) => element.id !== id)
      setNotesForm({
        id: setMaxId(filteredState),
        title: notesForm.title,
        text: notesForm.text,
      })
      dispatch(deleteNote(id))
    }
  }

  return (
    <Dashboard activePage="notatki">
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader firstItem={translate('notes')} />
          <div className="main__top">
            <MainTitle title={translate('notes')} />
          </div>
          <div className="notes-wrapper">
            {notes &&
              notes.map((element) => {
                return (
                  <Note
                    edit={edit}
                    remove={remove}
                    key={element.id}
                    id={element.id}
                    title={element.title}
                    text={element.text}
                    isActive={isActive}
                    setIsActive={setIsActive}
                  />
                )
              })}
          </div>
        </div>
        <MainAside>
          <TabTitle title={isActive ? translate('edit_note') : translate('add_note')} />
          <form className="notes-form" onSubmit={handleSubmit}>
            <input
              className="notes-form__input"
              placeholder={translate('title')}
              name="title"
              value={notesForm.title}
              onChange={(event) => handleInput(event)}
            />
            <textarea
              className="notes-form__textarea"
              name="text"
              rows={14}
              value={notesForm.text}
              placeholder={translate('content')}
              onChange={(event) => handleInput(event)}
            ></textarea>
            <button
              className="notes-form__button"
              type="submit"
              disabled={!notesForm.title || !notesForm.text}
            >
              {translate('save')}
            </button>
          </form>
        </MainAside>
      </div>
    </Dashboard>
  )
}

export default Notes
