import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import defaultImage from '../../../images/news-item.png'
import { useSelector } from 'react-redux'
import { selectSlug } from '../../redux/dashboard/selectors'

interface Props {
  id: number
  link: string
  text?: string
  person?: string
  date?: string
  image?: string
}

const TileItem: FC<Props> = ({ id, link, text, person, date, image }) => {
  const slug = useSelector(selectSlug)

  return (
    <li>
      <Link to={`/event/${slug}/${link || 'article'}/${id}`}>
        <div className="tiles-item">
          <img className="tiles-item__image" src={image || defaultImage} alt={`${link} item`} />
          {person && <img className="tiles-item__person" src={person} alt="person" />}
          {date && <span className="tiles-item__date">{date}</span>}
          {text && <span className="tiles-item__text">{text}</span>}
        </div>
      </Link>
    </li>
  )
}

export default TileItem
