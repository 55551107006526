import React, { FC } from 'react'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../../components/Icon'

interface Props {
  value: string
  onChange: (a: string) => void
}

const MainSearch: FC<Props> = ({ value, onChange }) => {
  return (
    <div className="main__search">
      <input
        className="main__search-input"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <Icon name={Icons.search} color={IconsColor.grey} type={IconsFillType.stroke} />
    </div>
  )
}

export default MainSearch
