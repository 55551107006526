import React, { FC, useState, useEffect } from 'react'
import Modal from 'react-modal'
import TabButton from '../TabButton'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../Icon'

import { useSelector, useDispatch } from 'react-redux'
import {
  asyncFetchAgendaItemAudienceQuestions,
  asyncFetchAudienceQuestions,
  asyncPostAudienceQuestion,
  asyncPostAudienceQuestionLike,
  asyncPostAudienceQuestionDislike,
} from '../../redux/dashboard/async'
import {
  selectSlug,
  selectAgendaItem,
  selectAudienceQuestions,
} from '../../redux/dashboard/selectors'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import { AudienceQuestion } from '../../redux/dashboard/interfaces'
import translate from '../../translate'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  },
}

Modal.setAppElement('#root')

interface QuestionProps {
  time: string
  text: string
  userName: string
  isLiked: boolean
  likesCount: number
  slug?: string
  id: number
}

const Question: FC<QuestionProps> = ({ slug, id, time, text, userName, isLiked, likesCount }) => {
  const dispatch = useDispatch()
  const token = useSelector(selectUserToken)

  const like = () => {
    if (slug) {
      dispatch(
        asyncPostAudienceQuestionLike({
          slug: slug,
          additionalHeaders: { Authorization: `JWT ${token}` },
          id: id,
        }),
      )
    }
  }

  const dislike = () => {
    if (slug) {
      dispatch(
        asyncPostAudienceQuestionDislike({
          slug: slug,
          additionalHeaders: { Authorization: `JWT ${token}` },
          id: id,
        }),
      )
    }
  }

  return (
    <div className="modal-question">
      <div className="modal-question__text">
        <h2>{text}</h2>
      </div>
      <div className="modal-question__likes">
        {isLiked ? (
          <button onClick={() => dislike()}>
            <Icon name={Icons.minus} color={IconsColor.white} type={IconsFillType.fill} />
          </button>
        ) : (
          <button onClick={() => like()}>
            <Icon name={Icons.plus} color={IconsColor.white} type={IconsFillType.fill} />
          </button>
        )}

        <span>{likesCount}</span>
      </div>
    </div>
  )
}

interface QuestionModalProps {
  id: number
  type?: string
  isAgendaItem?: boolean
}

const QuestionModal: FC<QuestionModalProps> = ({ id, type = 'button', isAgendaItem = true }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [, setQuestionIsOpen] = useState(false)

  const dispatch = useDispatch()

  const agendaItem = useSelector(selectAgendaItem(id))
  const audienceQuestions = useSelector(selectAudienceQuestions)
  const slug = useSelector(selectSlug)
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const [text, setText] = useState('')

  useEffect(() => {
    if (slug !== undefined) {
      if (isAgendaItem) {
        dispatch(
          asyncFetchAgendaItemAudienceQuestions({
            slug,
            id,
            additionalHeaders: { Authorization: `JWT ${token}` },
          }),
        )
      } else {
        dispatch(
          asyncFetchAudienceQuestions({
            slug,
            additionalHeaders: { Authorization: `JWT ${token}` },
          }),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setQuestionIsOpen(false)
  }

  const addQuestion = () => {
    if (slug) {
      dispatch(
        asyncPostAudienceQuestion({
          slug: slug,
          additionalHeaders: { Authorization: `JWT ${token}` },
          body: {
            text: text,
            user_name: `${user?.firstname} ${user?.lastname}`,
          },
        }),
      )
      closeModal()
    }
  }

  return (
    <div>
      {type === 'text' ? (
        <p onClick={() => setIsOpen(true)}>{translate('ask_question')}</p>
      ) : (
        <TabButton
          text={translate('ask_question')}
          iconName={Icons.edit}
          iconColor={IconsColor.white}
          iconColorHover={IconsColor.xiaomi}
          onClick={openModal}
        />
      )}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="modal modal--question">
          <h2>{translate('ask_question')}</h2>

          <div className="modal-question__content">
            <div className="modal-question__write">
              <span>{translate('your_question')}</span>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={translate('write_a_message')}
              ></textarea>
              <button className="modal__send-btn" onClick={() => addQuestion()}>
                {translate('send')}
              </button>
            </div>

            <div className="modal-question__questions">
              {!isAgendaItem &&
                audienceQuestions?.map((element: AudienceQuestion) => {
                  return (
                    <Question
                      key={element.id}
                      slug={slug}
                      id={element.id}
                      time={element.datetime_created.slice(0, 11)}
                      text={element.text}
                      userName={element.user_name}
                      isLiked={element.is_liked}
                      likesCount={element.likes_count}
                    />
                  )
                })}

              {isAgendaItem &&
                agendaItem?.audience_questions_agenda_item?.map((element: AudienceQuestion) => {
                  return (
                    <Question
                      key={element.id}
                      slug={slug}
                      id={id}
                      time={element.datetime_created.slice(0, 11)}
                      text={element.text}
                      userName={element.user_name}
                      isLiked={element.is_liked}
                      likesCount={element.likes_count}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default QuestionModal
