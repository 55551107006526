import React from 'react'
import { ReactComponent as Folder } from '../../images/folder.svg'
import { ReactComponent as Scheduler } from '../../images/scheduler.svg'
import { ReactComponent as HeartFull } from '../../images/heart.svg'
import { ReactComponent as HeartOutlined } from '../../images/heart-outlined.svg'
import { ReactComponent as Bell } from '../../images/bell.svg'
import { ReactComponent as Calendar } from '../../images/calendar.svg'
import { ReactComponent as Mark } from '../../images/map-mark.svg'
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg'
import { ReactComponent as Star } from '../../images/star.svg'
import { ReactComponent as Share } from '../../images/share.svg'
import { ReactComponent as Edit } from '../../images/edit.svg'
import { ReactComponent as Download } from '../../images/download.svg'
import { ReactComponent as Heart } from '../../images/heart.svg'
import { ReactComponent as People } from '../../images/people.svg'
import { ReactComponent as Eye } from '../../images/eye.svg'
import { ReactComponent as Search } from '../../images/loupe.svg'
import { ReactComponent as Settings } from '../../images/settings.svg'
import { ReactComponent as SettingsProfile } from '../../images/settingsProfile.svg'
import { ReactComponent as Back } from '../../images/back.svg'
import { ReactComponent as Smile } from '../../images/smile.svg'
import { ReactComponent as Attachment } from '../../images/attach-paperclip-symbol.svg'
import { ReactComponent as Phone } from '../../images/phone.svg'
import { ReactComponent as CameraFull } from '../../images/camera-filled.svg'
import { ReactComponent as Message } from '../../images/message.svg'
import { ReactComponent as SpeechBubble } from '../../images/speech-bubble.svg'
import { ReactComponent as Remove } from '../../images/delete.svg'
import { ReactComponent as Pen } from '../../images/pen.svg'
import { ReactComponent as Exit } from '../../images/exit.svg'
import { ReactComponent as User } from '../../images/user.svg'
import { ReactComponent as Rectangles } from '../../images/rectangles.svg'
import { ReactComponent as Bus } from '../../images/bus.svg'
import { ReactComponent as Tram } from '../../images/tram.svg'
import { ReactComponent as Subway } from '../../images/subway.svg'
import { ReactComponent as Mail } from '../../images/mail.svg'
import { ReactComponent as PhoneCall } from '../../images/phone-call.svg'
import { ReactComponent as Plus } from '../../images/plus.svg'
import { ReactComponent as RightUp } from '../../images/right-up.svg'
import { ReactComponent as Prev } from '../../images/prev.svg'
import { ReactComponent as Next } from '../../images/next.svg'
import { ReactComponent as Email } from '../../images/email.svg'
import { ReactComponent as Tick } from '../../images/tick.svg'
import { ReactComponent as Cancel } from '../../images/cancel.svg'
import { ReactComponent as Building } from '../../images/building.svg'
import { ReactComponent as Menu } from '../../images/menu.svg'
import { ReactComponent as UpArrow } from '../../images/up-arrow.svg'
import { ReactComponent as DownArrow } from '../../images/arrow-down.svg'
import { ReactComponent as MeetingaIcon } from '../../images/meetinga.svg'
import { ReactComponent as Minus } from '../../images/minus.svg'
import { ReactComponent as Board } from '../../images/board.svg'

const icons: any = {
  scheduler: <Scheduler />,
  folder: <Folder />,
  heartFull: <HeartFull />,
  heartOutlined: <HeartOutlined />,
  bell: <Bell />,
  calendar: <Calendar />,
  mark: <Mark />,
  arrowRight: <ArrowRight />,
  star: <Star />,
  share: <Share />,
  edit: <Edit />,
  download: <Download />,
  heart: <Heart />,
  people: <People />,
  eye: <Eye />,
  search: <Search />,
  settings: <Settings />,
  settingsProfile: <SettingsProfile />,
  back: <Back />,
  smile: <Smile />,
  attachment: <Attachment />,
  phone: <Phone />,
  cameraFull: <CameraFull />,
  message: <Message />,
  speechBubble: <SpeechBubble />,
  remove: <Remove />,
  pen: <Pen />,
  exit: <Exit />,
  user: <User />,
  rectangles: <Rectangles />,
  bus: <Bus />,
  tram: <Tram />,
  subway: <Subway />,
  mail: <Mail />,
  phoneCall: <PhoneCall />,
  plus: <Plus />,
  rightUp: <RightUp />,
  prev: <Prev />,
  next: <Next />,
  cancel: <Cancel />,
  tick: <Tick />,
  email: <Email />,
  building: <Building />,
  menu: <Menu />,
  upArrow: <UpArrow />,
  downArrow: <DownArrow />,
  meetingaIcon: <MeetingaIcon />,
  minus: <Minus />,
  board: <Board />,
}

export default icons
