import React, { FC, useState } from 'react'
import translate from '../../translate'

interface Props {
  onClick: (a: string) => void
  isActive: boolean
}

const NetworkingInput: FC<Props> = ({ onClick, isActive }) => {
  const [text, setText] = useState('')

  return (
    <div className="networking-input">
      {/* <Icon name={Icons.attachment} color={IconsColor.black} hoverColor={IconsColor.black} /> */}
      <div className="networking-input__container">
        <input
          className="networking-input__input"
          placeholder={translate('write_a_message')}
          maxLength={256}
          type="text"
          value={text}
          disabled={!isActive}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick(text)
              setText('')
            }
          }}
        />
        {/* <Icon
          className="networking-input__icon"
          name={Icons.smile}
          color={IconsColor.xiaomi}
          hoverColor={IconsColor.black}
        /> */}
      </div>
      <button
        disabled={!isActive}
        onClick={() => {
          onClick(text)
          setText('')
        }}
      >
        {translate('send')}
      </button>
    </div>
  )
}

export default NetworkingInput
