import React, { FormEvent } from 'react'
import { Typography, makeStyles, Button, Theme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

interface RoomNameScreenProps {
  name: string
  roomName: string
  setName: (name: string) => void
  setRoomName: (roomName: string) => void
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export default function RoomNameScreen({ setName, setRoomName }: RoomNameScreenProps) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Please enter a room from agenda.
      </Typography>

      <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
        Continue
      </Button>
    </>
  )
}
