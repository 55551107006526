import React, { FC } from 'react'

import TabTitle from '../TabTitle'
import Logo from '../../../images/building.svg'
import translate from '../../translate'

interface Props {
  logo?: string
  name?: string
  text?: string
}

const ExhibitorDescription: FC<Props> = ({ logo, name, text }) => {
  return (
    <>
      <TabTitle title={translate('description')} />
      <div className="exhibitor-description">
        <div className="exhibitor-description__wrapper">
          <img src={logo ? logo : Logo} alt="logo" />
        </div>

        <p>{name}</p>

        {text && (
          <div
            className="exhibitor-description__content"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        )}
      </div>
    </>
  )
}

export default ExhibitorDescription
