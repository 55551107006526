import React, { FC } from 'react'
import PersonImage from '../PersonImage'
import { formatDistance } from 'date-fns'
import { enUS, pl } from 'date-fns/locale'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../redux/dashboard/selectors'
import { Languages } from '../../utils/enums'

interface Props {
  type: string
  isOnline: boolean
  name: string
  img: string
  message: string
  date?: Date
}

const NetworkingMessage: FC<Props> = ({ type, date, isOnline, name, img, message }) => {
  const lang = useSelector(selectLanguage)
  return (
    <div className={`networking-message networking-message--${type}`}>
      <div className="networking-message__user">
        <span>{name}</span>
        <p>{message}</p>
        {date && (
          <span>
            {formatDistance(new Date(date), new Date(), {
              addSuffix: true,
              locale: lang === Languages.en ? enUS : pl,
            })}
          </span>
        )}
      </div>
      <PersonImage isOnline={isOnline} name={name} img={img} />
    </div>
  )
}

export default NetworkingMessage
