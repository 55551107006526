export const APP_VERSION = process.env.REACT_APP_MEETINGA_API_VERSION
// MEETINGA AGGREGATE KEY
export const API_KEY = process.env.REACT_APP_MEETINGA_API_KEY || 'm$Q!lG8g=9Y3S#dJ'

export const EVENT_URL = process.env.REACT_APP_MEETINGA_URL
export const LANGUAGE = process.env.REACT_APP_MEETINGA_LANGUAGE
export const HEADERS = {
  'App-Version': APP_VERSION,
  'Api-Key': API_KEY,
}
