import React, { FC } from 'react'

interface Props {
  logo?: string
  exhibitor?: string
  children?: JSX.Element
}

const ExhibitorBar: FC<Props> = ({ logo, exhibitor, children }) => {
  return (
    <div className={`exhibitor exhibitor--${exhibitor}`}>
      {logo && (
        <div className="exhibitor__logo">
          <img src={logo} alt="logo" />
        </div>
      )}

      {children || (
        <div className="exhibitor__text">
          <span>{exhibitor}</span>
        </div>
      )}
    </div>
  )
}

export default ExhibitorBar
