import React, { FC } from 'react'
import Dashboard from '../../views/Dashboard'
import MainTitle from '../../components/MainTitle'

import { useSelector } from 'react-redux'
import { selectAddresses, selectPeople } from '../../redux/dashboard/selectors'
import Contact from '../../components/Contact'
import Person from '../../components/Person'
import translate from '../../translate'

const Access: FC = () => {
  const addresses = useSelector(selectAddresses)
  const people = useSelector(selectPeople)

  return (
    <Dashboard activePage="contact">
      <div className="main__container">
        <div className="main__wrapper">
          <div className="main__top">
            <MainTitle title={translate('contact')} />
          </div>

          <div className="lecturers">
            {people?.map((element) =>
              element.show_in_contact ? (
                <a
                  className="lecturer-person"
                  key={element.id}
                  href={`tel:${element?.phone?.replace(/ /g, '')}`}
                >
                  <Person
                    img={element.photo_url}
                    name={`${element.firstname} ${element.lastname}`}
                    title={element.post}
                    phone={element?.phone}
                    email={element.email}
                  />
                </a>
              ) : null,
            )}
          </div>

          <div className="addresses">
            {addresses?.map((element) => {
              return element.show_in_contact ? <Contact key={element.id} {...element} /> : null
            })}
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Access
