import React, { useState, useEffect, FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Login from '../../views/Login'
import LoginInput from '../../components/LoginInput'
import { asyncFetchLogin } from '../../redux/user/async'
import { selectUserLoading, selectUser, selectUserToken } from '../../redux/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { LoginProps } from '../../redux/user/interfaces'
import translate from '../../translate'

import { Button } from 'antd'

const LoginPage: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const loading = useSelector(selectUserLoading)

  const [loginForm, setLoginForm] = useState({ login: '', password: '' })

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const body: LoginProps = { email: loginForm.login, password: loginForm.password }
    dispatch(asyncFetchLogin({ additionalHeaders: {}, body }))
  }

  useEffect(() => {
    if (user !== null && token !== null) {
      history.push('/welcome')
    }
  }, [user, token, history])

  return (
    <Login>
      <div className="login-page">
        <h1>{translate('sign_in')}</h1>
        <p>{translate('sign_in_text')}</p>

        <form onSubmit={handleSubmit}>
          <LoginInput
            label={translate('email')}
            name="login"
            required
            placeholder={translate('email')}
            type="email"
            onChange={setLoginForm}
          />
          <LoginInput
            label={translate('password')}
            name="password"
            type="password"
            minLength={2}
            placeholder={translate('password')}
            required
            onChange={setLoginForm}
          />

          <Button type="primary" loading={loading} htmlType="submit">
            {translate('login')}
          </Button>
        </form>

        <div className="login-page__links">
          <span>
            {translate('no_account')}{' '}
            <Link className="login-page__link" to="/rejestracja">
              {translate('register')}
            </Link>
          </span>
          <Link className="login-page__link" to="/przypomnij-haslo">
            {translate('not_remember_password')}
          </Link>
        </div>
      </div>
    </Login>
  )
}

export default LoginPage
