import React, { FC } from 'react'
import icons from '../../utils/icons'
import { IconsColor, IconsFillType } from '../../utils/enums'

interface Props {
  name: string
  pointer?: boolean
  className?: string
  color?: IconsColor
  hoverColor?: IconsColor
  type?: IconsFillType
}

const Icon: FC<Props> = ({
  name,
  className,
  color = 'grey',
  hoverColor,
  type = 'fill',
  pointer,
}) => {
  return (
    <div
      className={`icon icon--${type} icon--${color}
      ${className !== undefined ? ` ${className}` : ''}
      ${hoverColor !== undefined ? ` icon--${hoverColor}-hover` : ''}
      ${pointer ? 'icon--pointer' : ''}`}
    >
      {icons[name]}
    </div>
  )
}

export default Icon
