import React, { FC, useState } from 'react'
import Modal from 'react-modal'
import translate from '../../translate'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10000,
  },
}

Modal.setAppElement('#root')

interface AskingModalProps {
  buttonText: string
  text: string
  func: () => void
}

const AskingModal: FC<AskingModalProps> = ({ buttonText, text, func }) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setIsOpen(true)}>{buttonText}</button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} style={customStyles}>
        <div className="asking-modal">
          <h2>{text}</h2>
          <div>
            <button className="asking-modal__send-btn" onClick={() => setIsOpen(false)}>
              {translate('cancel')}
            </button>
            <button className="asking-modal__send-btn" onClick={func}>
              {translate('confirm')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AskingModal
