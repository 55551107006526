import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../../views/Dashboard'
import NavigationHeader from '../../components/NavigationHeader'
import MainTitle from '../../components/MainTitle'
import { useSelector, useDispatch } from 'react-redux'
import { selectSlug, selectExhibitor } from '../../redux/dashboard/selectors'
import { asyncFetchExhibitor } from '../../redux/dashboard/async'
import translate from '../../translate'
import { selectUserToken } from '../../redux/user/selectors'

interface PageParams {
  id: string
}

const Exhibitor: React.FC = () => {
  const { id } = useParams<PageParams>()

  const dispatch = useDispatch()

  const exhibitor = useSelector(selectExhibitor(id))
  const slug = useSelector(selectSlug)
  const token = useSelector(selectUserToken)

  useEffect(() => {
    if (slug !== undefined) {
      dispatch(
        asyncFetchExhibitor({
          slug: slug,
          id: id,
          additionalHeaders: { Authorization: `JWT ${token}` },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  return (
    <Dashboard>
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader firstItem={translate('exhibitors')} secondItem={exhibitor?.name} />
          {exhibitor && (
            <>
              <div className="main__top">
                <MainTitle title={exhibitor?.name} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: exhibitor?.content || '' }}></div>
            </>
          )}
        </div>
        {/* <MainAside>
          <TabTitle title="Xiaomi" />
          <MainAsideItem
            exhibitorName={exhibitorName}
            img={getIcon('xiaomi-logo')}
            backgroundColor={Color.orange}
            title="O nas"
          />
          <MainAsideItem
            exhibitorName={exhibitorName}
            img={getIcon('xiaomi-logo')}
            backgroundColor={Color.orange}
            title="Dystrybucja"
          />
          <MainAsideItem
            exhibitorName={exhibitorName}
            img={getIcon('xiaomi-logo')}
            backgroundColor={Color.orange}
            title="Strona www"
          />
          <TabTitle title="Nowe produkty" />
          <MainAsideItem
            exhibitorName={exhibitorName}
            iconName={Icons.phone}
            backgroundColor={Color.yellow}
            title="Xiaomi MiBand 5"
          />
          <MainAsideItem
            exhibitorName={exhibitorName}
            iconName={Icons.phone}
            backgroundColor={Color.yellow}
            title="Xiaomi Mi 10"
          />
          <MainAsideItem
            exhibitorName={exhibitorName}
            iconName={Icons.phone}
            backgroundColor={Color.yellow}
            title="Xiaomi Pods"
          />
          <MainAsideItem
            exhibitorName={exhibitorName}
            iconName={Icons.phone}
            backgroundColor={Color.yellow}
            title="Xiaomi Home"
          />
          <TabTitle title="Oferta" />
          <MainAsideItem
            exhibitorName={exhibitorName}
            iconName={Icons.cameraFull}
            backgroundColor={Color.blue}
            title="Film promocyjny"
          />
          <MainAsideItem
            exhibitorName={exhibitorName}
            iconName={Icons.message}
            backgroundColor={Color.blue}
            title="Materiały reklamowe"
          />
          <TabTitle title="Kontakt" />
          <MainAsideItem
            exhibitorName={exhibitorName}
            iconName={Icons.speechBubble}
            backgroundColor={Color.purple}
            title="Skontaktuj się z nami"
          />
          <div className="exhibitor-stand-buttons">
            <TabButton
              iconName={Icons.share}
              iconColor={IconsColor.white}
              iconColorHover={IconsColor.xiaomi}
            />
          </div>
        </MainAside> */}
      </div>
    </Dashboard>
  )
}

export default Exhibitor
