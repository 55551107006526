import React, { FC, useMemo } from 'react'
import Icon from '../Icon'
import { Icons, IconsColor, IconsFillType } from '../../utils/enums'

interface Props {
  text?: string
  className?: string | null
  iconName: Icons
  iconColor?: IconsColor
  iconColorHover?: IconsColor
  iconType?: IconsFillType
  onClick?: () => void
}

const TabButton: FC<Props> = ({
  text,
  className = null,
  iconName,
  iconColor,
  iconColorHover,
  iconType,
  onClick,
}) => {
  const hoverColor = useMemo(() => {
    if (iconColorHover) {
      return IconsColor[iconColorHover]
    } else if (iconColor) {
      return IconsColor[iconColor]
    } else {
      return IconsColor.grey
    }
  }, [iconColorHover, iconColor])

  return (
    <button className="tab-button" onClick={onClick}>
      <div className="tab-button__container">
        <Icon
          name={Icons[iconName]}
          color={iconColor ? IconsColor[iconColor] : IconsColor.grey}
          hoverColor={hoverColor}
          type={iconType ? IconsFillType[iconType] : IconsFillType.fill}
        />
        {text && (
          <span className={className ? `tab-button__text${className}` : 'tab-button__text'}>
            {text}
          </span>
        )}
      </div>
    </button>
  )
}

export default TabButton
