import React, { FC } from 'react'
interface Props {
  title: string
  className?: string
}

const TabTitle: FC<Props> = ({ title, className }) => {
  return <h1 className={`tab__title ${className ? className : ''}`}>{title}</h1>
}

export default TabTitle
