import React, { FC } from 'react'
import Person from '../Person'
import Icon from '../Icon'
import { IconsColor, Icons } from '../../utils/enums'

interface Props {
  isOnline: boolean
  id: number
  name: string
  img: string
  title: string
  status?: string
  activeChat: number | null
  setActiveChat: (a: number) => void
}

const NetworkingAsideTile: FC<Props> = ({
  isOnline,
  id,
  name,
  img,
  title,
  status,
  activeChat,
  setActiveChat,
}) => {
  return (
    <div
      className={`networking-aside-tile__wrapper ${
        activeChat === id ? ' networking-aside-tile__wrapper--active' : ''
      }`}
    >
      <div className="networking-aside-tile" onClick={() => setActiveChat(id)}>
        <Person img={img} name={name} isOnline={isOnline} title={title} />
        <Icon
          className="localization__arrow localization__arrow--networking"
          name={Icons.arrowRight}
          color={IconsColor.black}
          hoverColor={IconsColor.white}
        />
      </div>
      <div className="networking-aside-tile__status">
        <span>{status}</span>
      </div>
    </div>
  )
}

export default NetworkingAsideTile
