interface PropsObject {
  id: number
  title: string
  text: string
}

type Props = PropsObject[]

const setMaxId = (arr: Props) => {
  return arr.length === 0 ? 1 : Math.max(...arr.map((element) => element.id)) + 1
}

export default setMaxId
