import React, { FC, useState, useEffect } from 'react'

import { Marker, ImageOverlay, useMap, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { greenIcon, blueIcon, greyIcon } from '../../utils/leafletIcons'

import MapUnavailable from '../../../images/map-unavailable.png'
import { cutString } from '../../utils/functions'

interface MapViewProps {
  x: number
  y: number
  mapUrl?: string
}

interface MapMarkProps {
  x: number
  y: number
  name: string
  isDisplayed: boolean
  selectedId: number | null
  isSelected: boolean | null
  id: number
  onClick: (a: number | null) => void
}

interface TooltipMarkProps {
  name: string
  id: number
  onClick: (a: number | null) => void
  position: L.LatLng
  icon: L.Icon
  isPermanent: boolean
}

export const MapView: FC<MapViewProps> = ({ x, y, mapUrl }) => {
  const map = useMap()

  const point = L.point(x, y)
  const [position] = useState(map.layerPointToLatLng(point))

  const pointZero = L.point(0, 0)
  const [positionZero] = useState(map.layerPointToLatLng(pointZero))

  const bounds: [number, number][] = [
    [position.lat, position.lng],
    [positionZero.lat, positionZero.lng],
  ]

  useEffect(() => {
    map.fitBounds(bounds)
    map.setMaxBounds(bounds)
    map.setMinZoom(map.getBoundsZoom(bounds))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ImageOverlay url={mapUrl || MapUnavailable} bounds={bounds} />
}

export const TooltipMark: FC<TooltipMarkProps> = ({
  onClick,
  id,
  position,
  name,
  icon,
  isPermanent,
}) => {
  return (
    <Marker
      eventHandlers={{
        click: () => {
          onClick(id)
        },
      }}
      icon={icon}
      position={[position.lat, position.lng]}
    >
      <Tooltip direction="top" permanent={isPermanent}>
        {cutString(name, 16)}
      </Tooltip>
    </Marker>
  )
}

export const MapMark: FC<MapMarkProps> = ({
  x,
  y,
  name,
  isDisplayed,
  selectedId,
  isSelected,
  id,
  onClick,
}) => {
  const map = useMap()

  const point = L.point(x, y)
  const [position] = useState(map.layerPointToLatLng(point))

  return (
    <>
      {isDisplayed && isSelected && (
        <TooltipMark
          onClick={onClick}
          id={id}
          position={position}
          name={name}
          icon={greenIcon}
          isPermanent={true}
        />
      )}
      {isDisplayed && selectedId === null && (
        <TooltipMark
          onClick={onClick}
          id={id}
          position={position}
          name={name}
          icon={blueIcon}
          isPermanent={true}
        />
      )}
      {isDisplayed && selectedId !== null && !isSelected && (
        <TooltipMark
          onClick={onClick}
          id={id}
          position={position}
          name={name}
          icon={greyIcon}
          isPermanent={false}
        />
      )}
    </>
  )
}
