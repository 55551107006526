import React, { FC } from 'react'
import Dashboard from '../../views/Dashboard'
import MainTitle from '../../components/MainTitle'
import ImageGallery from 'react-image-gallery'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectGalleryItem } from '../../redux/dashboard/selectors'
import translate from '../../translate'
import 'react-image-gallery/styles/css/image-gallery.css'

interface PageParams {
  id: string
}

const Gallery: FC = () => {
  const { id } = useParams<PageParams>()

  const gallery = useSelector(selectGalleryItem(id))

  return (
    <Dashboard>
      <div className="main__container">
        <div className="main__wrapper">
          <div className="main__top">
            <MainTitle title={`${translate('gallery')} ${gallery?.name}`} />
          </div>

          {gallery && (
            <ImageGallery
              items={gallery.items.map((element) => ({
                original: element.image_url,
                thumbnail: element.thumbnail_url,
              }))}
            />
          )}
        </div>
      </div>
    </Dashboard>
  )
}

export default Gallery
