import React from 'react'
import Dashboard from '../../views/Dashboard'
import NavigationHeader from '../../components/NavigationHeader'
import MainTitle from '../../components/MainTitle'
import translate from '../../translate'

const Favorites: React.FC = () => {
  return (
    <Dashboard activePage="ulubione">
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader firstItem={translate('favorites')} />
          <div className="main__top">
            <MainTitle title={translate('favorites')} />
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Favorites
