import React, { useState } from 'react'

export const Tab: React.FC<{ children: JSX.Element; title?: string }> = ({ children, title }) => {
  return <div className="single-tab">{children}</div>
}

export const Tabs: React.FC<{ children: JSX.Element[] }> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0)
  const tabsData = children && children?.map((item: JSX.Element) => item?.props)

  return (
    <div className="tabs-component">
      <div className="tabs-header">
        {tabsData?.length > 0 &&
          tabsData?.map((item: { title: string }, index: number) => (
            <React.Fragment key={index}>
              <>
                {item?.title && (
                  <button
                    className={`transparent${activeTab === index ? ' active' : ''}`}
                    onClick={() => setActiveTab(index)}
                  >
                    {item.title}
                  </button>
                )}
              </>
            </React.Fragment>
          ))}
      </div>
      <div className="tabs-content">{children[activeTab]}</div>
    </div>
  )
}
