import React, { FC } from 'react'
import Icon from '../Icon'
import { Icons, IconsColor } from '../../utils/enums'

interface Props {
  filename: string
  link: string
}

const DownloadFile: FC<Props> = ({ filename, link }) => {
  return (
    <a className="download__link" href={link}>
      <div className="download__container">
        <span>{filename}</span>
        <Icon name={Icons.download} color={IconsColor.black} hoverColor={IconsColor.xiaomi} />
      </div>
    </a>
  )
}

export default DownloadFile
