import React, { useState, useEffect, FC } from 'react'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../../components/Icon'
import Dashboard from '../../views/Dashboard'
import MainAside from '../../components/MainAside'
import ProfileAsideItem from '../../components/ProfileAsideItem'
import ProfileEdit from '../../components/ProfileEdit'
import ProfileSettings from '../../components/ProfileSettings'
import ProfileEvents from '../../components/ProfileEvents'
import ProfilePassword from '../../components/ProfilePassword'
import { useDispatch, useSelector } from 'react-redux'
import { selectWidth } from '../../redux/dashboard/selectors'
import { logout } from '../../redux/user/slice'
import translate from '../../translate'

const Profile: FC = () => {
  const dispatch = useDispatch()

  const [activePage, setActivePage] = useState('profile')
  const [menu, setMenu] = useState(false)

  const width = useSelector(selectWidth)

  useEffect(() => {
    setMenu(false)
  }, [activePage])

  return (
    <Dashboard>
      <div className="main__container">
        {(width >= 1025 || menu) && (
          <MainAside>
            <ProfileAsideItem
              text={translate('edit_profile')}
              onClick={setActivePage}
              activePage={activePage}
              page="profile"
              iconName={Icons.user}
              iconColor={IconsColor.black}
            />
            <ProfileAsideItem
              text={translate('change_password')}
              onClick={setActivePage}
              activePage={activePage}
              page="password"
              iconName={Icons.settingsProfile}
              iconColor={IconsColor.black}
            />
            <ProfileAsideItem
              text={translate('settings_agreements')}
              onClick={setActivePage}
              activePage={activePage}
              page="settings"
              iconName={Icons.settings}
              iconColor={IconsColor.black}
              iconType={IconsFillType.stroke}
            />
            <ProfileAsideItem
              text={translate('logout')}
              page="logout"
              className="profile-aside-item--logout"
              iconName={Icons.exit}
              iconColor={IconsColor.tesla}
              onClick={() => dispatch(logout())}
            />
          </MainAside>
        )}
        {(width >= 1025 || !menu) && (
          <div className="main__wrapper profile">
            {activePage === 'profile' && <ProfileEdit />}
            {activePage === 'settings' && <ProfileSettings />}
            {activePage === 'events' && <ProfileEvents />}
            {activePage === 'password' && <ProfilePassword />}
          </div>
        )}
      </div>
      <div className="sidebar__mobile-bar-wrapper sidebar__mobile-bar-wrapper-agenda">
        <div className="agenda-bar-item">
          <span>{translate('open_menu')}</span>
          <button
            onClick={() => {
              setMenu(true)
            }}
          >
            <Icon
              name={Icons.upArrow}
              color={IconsColor.xiaomi}
              hoverColor={IconsColor.xiaomi}
              type={IconsFillType.fill}
            />
          </button>
        </div>
      </div>
    </Dashboard>
  )
}

export default Profile
