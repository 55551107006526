import React, { FC } from 'react'
import PersonImage from '../PersonImage'

interface Props {
  img?: string
  name: string
  isOnline?: boolean
  title?: string
  status?: string
  className?: string
  phone?: string
  email?: string
}

const Person: FC<Props> = ({ img, email, phone, name, isOnline, title, status, className }) => {
  return (
    <div className={`person ${className ? className : ''}`}>
      {img && <PersonImage name={name} img={img} isOnline={isOnline} />}
      <div className="person__wrapper">
        <span>
          {name} {status && <small className="person__status">{`- ${status}`}</small>}
        </span>

        {title && <small>{title}</small>}
        {phone && <small>{phone}</small>}
        {email && <small>{email}</small>}
      </div>
    </div>
  )
}

export default Person
