import React, { FC } from 'react'
import Dashboard from '../../views/Dashboard'
import MainTitle from '../../components/MainTitle'
import TileItem from '../../components/TileItem'
import defaultImage from '../../../images/news-item.png'
import NavigationHeader from '../../components/NavigationHeader'

import { cutString } from '../../utils/functions'
import { useSelector } from 'react-redux'
import { selectGalleries } from '../../redux/dashboard/selectors'
import { GalleriesProps } from '../../redux/dashboard/interfaces'
import translate from '../../translate'

const Gallery: FC = () => {
  const galleries = useSelector(selectGalleries)

  return (
    <Dashboard activePage="gallery">
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader firstItem={translate('gallery')} />
          <div className="main__top">
            <MainTitle title={translate('gallery')} />
          </div>
          <ul className="tiles__wrapper">
            {galleries?.map((element: GalleriesProps) => (
              <TileItem
                key={element.id}
                id={element.id}
                link="gallery"
                image={element.icon_url || defaultImage}
                text={cutString(element.name, 64)}
              />
            ))}
          </ul>
        </div>
      </div>
    </Dashboard>
  )
}

export default Gallery
