import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEvent, apiEvents } from '../../services/api'
import {
  AgendaItemProps,
  ArticleProps,
  AudienceQuestions,
  Dashboard,
  Events,
  MapPointProps,
  PeopleItemProps,
} from './interfaces'
import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import { errorsToArray } from '../../utils/functions'

export const asyncFetchEventContent = createAsyncThunk(
  'dashboard/asyncFetchEventContent',
  async ({ slug, additionalHeaders }: { slug: string; additionalHeaders: any }, thunkAPI) => {
    const response = await apiEvent(slug, additionalHeaders).get<Dashboard>('events/content/')
    return response.data
  },
)

export const asyncFetchEvents = createAsyncThunk(
  'dashboard/asyncFetchEvents',
  async ({ additionalHeaders }: { additionalHeaders: any }, thunkAPI) => {
    const response = await apiEvents(additionalHeaders).get<Events[]>('events/')
    return response.data
  },
)

export const asyncFetchArticle = createAsyncThunk(
  'dashboard/asyncFetchArticle',
  async (
    {
      slug,
      id,
      additionalHeaders,
    }: { slug: string; id: string | number | null; additionalHeaders: any },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug!, additionalHeaders).get<ArticleProps>(`articles/${id}/`)
    return response.data
  },
)

export const asyncFetchExhibitor = createAsyncThunk(
  'dashboard/asyncFetchExhibitor',
  async (
    {
      slug,
      id,
      additionalHeaders,
    }: { slug: string; id: string | number | null; additionalHeaders: any },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders).get<MapPointProps>(
      `maps/map_points/${id}/`,
    )
    return response.data
  },
)

export const asyncFetchAgendaItem = createAsyncThunk(
  'dashboard/asyncFetchAgendaItem',
  async (
    {
      slug,
      id,
      additionalHeaders,
    }: { slug: string; id: string | number | null; additionalHeaders: any },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders).get<AgendaItemProps>(
      `agenda/agenda_items/${id}/`,
    )
    return response.data
  },
)

export const asyncFetchAudienceQuestions = createAsyncThunk(
  'dashboard/asyncFetchAudienceQuestions',
  async ({ slug, additionalHeaders }: { slug: string; additionalHeaders: any }, thunkAPI) => {
    const response = await apiEvent(slug, additionalHeaders).get<AudienceQuestions>(
      `/polls/audience_question/`,
    )
    return response.data
  },
)

export const asyncFetchAgendaItemAudienceQuestions = createAsyncThunk(
  'dashboard/asyncFetchAgendaItemAudienceQuestions',
  async (
    {
      slug,
      id,
      additionalHeaders,
    }: { slug: string; id: string | number | null; additionalHeaders: any },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders).get<AudienceQuestions>(
      `/polls/audience_question/agenda_item/${id}/`,
    )
    return response.data
  },
)

export const asyncFetchPeopleItem = createAsyncThunk(
  'dashboard/asyncFetchPeopleItem',
  async (
    {
      slug,
      id,
      additionalHeaders,
    }: { slug: string; id: string | number | null; additionalHeaders: any },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders).get<PeopleItemProps>(`/people/${id}/`)
    return response.data
  },
)

export const asyncFetchPeopleNetworking = createAsyncThunk(
  'dashboard/asyncFetchPeopleNetworking',
  async ({ slug, additionalHeaders }: { slug: string; additionalHeaders: any }, thunkAPI) => {
    const response = await apiEvent(slug, additionalHeaders).get<any>(`/networking/`)
    return response.data
  },
)

// export const asyncPostAudienceQuestion = createAsyncThunk(
//   'dashboard/asyncPostAudienceQuestion',
//   async ({ slug, additionalHeaders }: { slug: string; additionalHeaders: any }, thunkAPI) => {
//     const response = await apiEvent(slug, additionalHeaders).post<any>(`/polls/audience_question/`)
//     return response.data
//   },
// )

export const asyncPostPoll = createAsyncThunk(
  'user/asyncPostPoll',
  async (
    {
      slug,
      additionalHeaders,
      body,
      pollSlug,
      id,
    }: { slug: string; additionalHeaders: any; body: any; pollSlug: string; id: number },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders)
      .post<any>(`/polls/model/${pollSlug}/${id}/`, body)
      .catch(function(error) {
        if (error.response) {
          errorsToArray(error.response.data).forEach((el) =>
            dashboardMessage(DashboardMessageTypes.warning, el),
          )
        }
      })

    if (response) {
      dashboardMessage(DashboardMessageTypes.success, 'Dziękujemy za oddanie głosu.')
      return response.data
    }
  },
)

export const asyncPostAudienceQuestion = createAsyncThunk(
  'user/asyncPostAudienceQuestion',
  async (
    { slug, additionalHeaders, body }: { slug: string; additionalHeaders: any; body: any },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders)
      .post<any>('/polls/audience_question/', body)
      .catch(function(error) {
        if (error.response) {
          errorsToArray(error.response.data).forEach((el) =>
            dashboardMessage(DashboardMessageTypes.warning, el),
          )
        }
      })

    if (response) {
      dashboardMessage(
        DashboardMessageTypes.success,
        'Po zaakceptowaniu pytania przez administratora wyświetli się ona w aplikacji.',
      )
      return response.data
    }
  },
)

export const asyncPostAudienceQuestionLike = createAsyncThunk(
  'user/asyncPostAudienceQuestionLike',
  async (
    { slug, additionalHeaders, id }: { slug: string; additionalHeaders: any; id: number },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders)
      .post<any>(`/polls/audience_question/${id}/like/`)
      .catch(function(error) {
        if (error.response) {
          errorsToArray(error.response.data).forEach((el) =>
            dashboardMessage(DashboardMessageTypes.warning, el),
          )
        }
      })

    if (response) {
      dashboardMessage(DashboardMessageTypes.success, 'Polubiono pytanie.')
      thunkAPI.dispatch(asyncFetchAudienceQuestions({ slug, additionalHeaders: additionalHeaders }))
      return response.data
    }
  },
)

export const asyncPostAudienceQuestionDislike = createAsyncThunk(
  'user/asyncPostAudienceQuestionDislike',
  async (
    { slug, additionalHeaders, id }: { slug: string; additionalHeaders: any; id: number },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders)
      .post<any>(`/polls/audience_question/${id}/dislike/`)
      .catch(function(error) {
        if (error.response) {
          errorsToArray(error.response.data).forEach((el) =>
            dashboardMessage(DashboardMessageTypes.warning, el),
          )
        }
      })

    if (response) {
      dashboardMessage(DashboardMessageTypes.success, 'Już nie lubisz tego pytania.')
      thunkAPI.dispatch(asyncFetchAudienceQuestions({ slug, additionalHeaders: additionalHeaders }))
      return response.data
    }
  },
)
